import React, { useEffect, useState, useContext } from "react";

// import { Accordion, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import NumberFormat from "react-number-format";

import { CartContext } from "./contexts/CartContext";

import TextareaAutosize from "react-textarea-autosize";

// import { ToastContainer, toast } from "react-toastify";

import Swal from "sweetalert2/dist/sweetalert2.js";

import environment from "../environments/environment";

export const CheckoutPage = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [promocode, setPromoCode] = useState("");
    const [shipprice, setShipPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [promolist, setPromolist] = useState([]);

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [contactnumber, setContactnumber] = useState("");
    const [email, setEmail] = useState("");
    const [province, setProvince] = useState("");
    const [district, setDistrict] = useState("");
    const [subdistrict, setSubdistrict] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [address, setAddress] = useState("");

    const { total, cartItems, clearCart } = useContext(CartContext);

    useEffect(() => {
        cartItems.map((object) =>
            setShipPrice(shipprice + object.shipping_price * object.quantity)
        );
        // setFinnalPrice(total + shipprice);
    }, []);

    // const showToast = (errortext) => {
    //     toast.error(errortext, {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: false,
    //         pauseOnHover: true,
    //         draggable: false,
    //         progress: undefined,
    //     });
    // };

    const checkPromoCode = (promocode) => {
        setLoading(true);
        let today = new Date();
        fetch(
            environment.url +
                environment.version +
                "/feelsshop/getcouponresult",
            {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    secret: environment.secret,
                },
                body: JSON.stringify({
                    coupon_code: promocode,
                    total_amount: (
                        parseFloat(total) + parseFloat(shipprice)
                    ).toString(),
                    total_shipping: shipprice.toString(),
                }),
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === "0") {
                    console.log(result);
                    let promocodevalue = Object.values(result.result);
                    let expiredate = new Date(promocodevalue["0"].expire_date);
                    // // console.log(expiredate);
                    if (
                        promocodevalue["0"].status === "disable" ||
                        promocodevalue["0"].usage > promocodevalue["0"].limit ||
                        today > expiredate
                    ) {
                        Swal.fire({
                            title: "ขออภัย",
                            text: "รหัสส่วนลดนี้ไม่พร้อมใช้งานในขณะนี้",
                            icon: "warning",
                            confirmButtonText: "รับทราบ",
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        });
                    } else {
                        setPromolist([
                            ...promolist,
                            promocodevalue["0"].coupon_code,
                        ]);
                        setDiscount(
                            parseFloat(promocodevalue["0"].total_discount)
                        );
                        // if (
                        //     promocodevalue["0"].discount_type === "percentage"
                        // ) {
                        //     setDiscount(
                        //         ((parseFloat(total) + parseFloat(shipprice)) *
                        //             promocodevalue["0"].discount) /
                        //             100
                        //     );
                        // } else {
                        //     setDiscount(promocodevalue["0"].discount);
                        // }
                        // Swal.fire({
                        //     // title: "ขออภัย",
                        //     text: "รหัสส่วนลดนี้สามารถใช้งานได้",
                        //     icon: "success",
                        //     confirmButtonText: "รับทราบ",
                        //     allowEscapeKey: false,
                        //     allowEnterKey: false,
                        // });
                    }
                    //     console.log(promocodevalue);
                    // Swal.fire({
                    //     // title: "ขออภัย",
                    //     text: "รหัสส่วนลดนี้สามารถใช้งานได้",
                    //     icon: "success",
                    //     confirmButtonText: "รับทราบ",
                    //     allowEscapeKey: false,
                    //     allowEnterKey: false,
                    // });
                } else if (result.responsecode === "100") {
                    if (result.result) {
                        setPromoCode("");
                        setPromolist([]);
                        Swal.fire({
                            title: "ขออภัย",
                            text:
                                "รหัสส่วนลดนี้ไม่สามารถใช้งานได้ เนื่องจากรหัสส่วนลดไม่ตรงตามเงื่อนไข",
                            icon: "warning",
                            confirmButtonText: "รับทราบ",
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        });
                    } else {
                        setPromoCode("");
                        setPromolist([]);
                        Swal.fire({
                            title: "ขออภัย",
                            text: "ไม่พบรหัสส่วนลดนี้",
                            icon: "warning",
                            confirmButtonText: "รับทราบ",
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        });
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    title: "ขออภัย",
                    text: "แพกเกจที่คุณเลือก ขายหมดแล้ว",
                    icon: "warning",
                    confirmButtonText: "รับทราบ",
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            });
    };

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
    };

    const createOrder = () => {
        if (fname === "") {
            Swal.fire({
                // title: "ขออภัย",
                text: "กรุณากรอกชื่อจริง",
                icon: "warning",
                confirmButtonText: "รับทราบ",
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        if (lname === "") {
            Swal.fire({
                // title: "ขออภัย",
                text: "กรุณากรอกนามสกุล",
                icon: "warning",
                confirmButtonText: "รับทราบ",
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        if (contactnumber === "") {
            Swal.fire({
                // title: "ขออภัย",
                text: "กรุณากรอกเอร์ติดต่อ",
                icon: "warning",
                confirmButtonText: "รับทราบ",
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        if (province === "") {
            Swal.fire({
                // title: "ขออภัย",
                text: "กรุณากรอกจังหวัด",
                icon: "warning",
                confirmButtonText: "รับทราบ",
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        if (district === "") {
            Swal.fire({
                // title: "ขออภัย",
                text: "กรุณากรอกเขต/อำเภอ",
                icon: "warning",
                confirmButtonText: "รับทราบ",
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        if (subdistrict === "") {
            Swal.fire({
                // title: "ขออภัย",
                text: "กรุณากรอกแขวง/ตำบล",
                icon: "warning",
                confirmButtonText: "รับทราบ",
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        if (zipcode === "") {
            Swal.fire({
                // title: "ขออภัย",
                text: "กรุณากรอกรหัสไปรษณีย์",
                icon: "warning",
                confirmButtonText: "รับทราบ",
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        if (address === "") {
            Swal.fire({
                // title: "ขออภัย",
                text: "กรุณากรอกที่อยู่",
                icon: "warning",
                confirmButtonText: "รับทราบ",
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        let order_items = [];
        let thisitem = {};
        cartItems.map((object, index) => {
            // console.log(object);
            thisitem = {
                productid: object.product_id.toString(),
                quantity: object.quantity.toString(),
                // attributes: {}.toString(),
            };
            order_items.push(thisitem);
        });
        let postparam = {
            firstname: fname,
            lastname: lname,
            contactnumber: contactnumber,
            province: province,
            district: district,
            subdistrict: subdistrict,
            zipcode: zipcode,
            address: address,
            order_items: order_items,
        };
        if (email !== "") postparam["email"] = email;
        if (promolist.length !== 0) postparam["coupon_items"] = promolist;
        // console.log(postparam);
        setLoading(true);
        fetch(
            environment.url + environment.version + "/feelsshop/createorder",
            {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    secret: environment.secret,
                },
                body: JSON.stringify(postparam),
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === "0") {
                    clearCart();
                    history.push({
                        pathname: "/result",
                        state: { result: result },
                    });
                } else {
                    setPromoCode("");
                    setPromolist([]);
                    Swal.fire({
                        title: "ขออภัย",
                        text:
                            "ไม่สามารถสร้างรายการสั่งซื้อได้เนื่องจากรหัสส่วนลดไม่ตรงตามเงื่อนไข กรุณาลองใหม่อีกครั้ง",
                        icon: "warning",
                        confirmButtonText: "รับทราบ",
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                // showToast("test");
                Swal.fire({
                    title: "ขออภัย",
                    text:
                        "ไม่สามารถสร้างรายการสั่งซื้อได้ กรุณาลองใหม่อีกครั้ง",
                    icon: "warning",
                    confirmButtonText: "รับทราบ",
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            });
        // console.log(fname);
        // console.log(lname);
        // console.log(contactnumber);
        // console.log(province);
        // console.log(district);
        // console.log(subdistrict);
        // console.log(zipcode);
        // console.log(address);
    };

    return (
        <>
            {loading && (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            {/* <ToastContainer /> */}
            <div className="row h-100 m-0 p-0">
                <div className="col-12">
                    <div className="card card-custom card-shadowless bg-white rounded h-100">
                        <div className="card-body p-0">
                            <div className="row justify-content-center align-items-center h-100 py-8 px-8 py-lg-15 px-lg-10 m-0">
                                <div className="col-xl-12 col-xxl-10">
                                    <div className="row d-flex justify-content-center pb-5">
                                        <div className="col-md-7">
                                            <div className="p-5">
                                                <div className="d-flex row m-0 p-2">
                                                    <div className="col-12">
                                                        <form>
                                                            <div>
                                                                <p>
                                                                    <i className="fas fa-user mr-3" />
                                                                    <b>
                                                                        ข้อมูลติดต่อ
                                                                    </b>
                                                                </p>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group col-md-6">
                                                                    <label>
                                                                        ชื่อ
                                                                        <span className="required-text">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="ชื่อผู้รับสินค้า (กรุณาใช้ชื่อจริง)"
                                                                        value={
                                                                            fname
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setFname(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label>
                                                                        นามสกุล
                                                                        <span className="required-text">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={
                                                                            lname
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setLname(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label>
                                                                        เบอร์ติดต่อ
                                                                        <span className="required-text">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="tel"
                                                                        className="form-control"
                                                                        value={
                                                                            contactnumber
                                                                        }
                                                                        onKeyPress={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                !/[0-9]/.test(
                                                                                    e.key
                                                                                )
                                                                            ) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        maxLength="10"
                                                                        onInput={
                                                                            maxLengthCheck
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setContactnumber(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label>
                                                                        อีเมล
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={
                                                                            email
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEmail(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div>
                                                                <p>
                                                                    <i className="fas fa-truck mr-3" />
                                                                    <b>
                                                                        ที่อยู่สำหรับการจัดส่ง
                                                                    </b>
                                                                </p>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group col-md-6">
                                                                    <label>
                                                                        จังหวัด
                                                                        <span className="required-text">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={
                                                                            province
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setProvince(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label>
                                                                        เขต/อำเภอ
                                                                        <span className="required-text">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={
                                                                            district
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setDistrict(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label>
                                                                        แขวง/ตำบล
                                                                        <span className="required-text">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={
                                                                            subdistrict
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setSubdistrict(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label>
                                                                        รหัสไปรษณีย์
                                                                        <span className="required-text">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        type="tel"
                                                                        className="form-control"
                                                                        value={
                                                                            zipcode
                                                                        }
                                                                        onKeyPress={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                !/[0-9]/.test(
                                                                                    e.key
                                                                                )
                                                                            ) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        maxLength="5"
                                                                        onInput={
                                                                            maxLengthCheck
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setZipcode(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group col-md-12">
                                                                    <label>
                                                                        ที่อยู่
                                                                        <span className="required-text">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <TextareaAutosize
                                                                        className="form-control"
                                                                        value={
                                                                            address
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setAddress(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 mobile">
                                            <div
                                                className="bg-light rounded d-flex flex-column p-3"
                                                id="cartsummary"
                                            >
                                                <div className="p-2 ml-3">
                                                    <h4>
                                                        สรุปรายละเอียดสินค้า
                                                    </h4>
                                                </div>
                                                {cartItems.map(
                                                    (object, index) => {
                                                        return (
                                                            <div
                                                                className="d-flex jusitfy-content-between align-items-center pt-3 pb-3 border-bottom"
                                                                key={index}
                                                            >
                                                                <div className="item pr-2">
                                                                    <img
                                                                        src={
                                                                            object
                                                                                .product_image[
                                                                                "0"
                                                                            ]
                                                                        }
                                                                        className="w-100-px"
                                                                        alt={`product-${object.product_id}`}
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column px-3">
                                                                    <b className="h5">
                                                                        {
                                                                            object.product_name
                                                                        }
                                                                    </b>
                                                                    <p className="m-0">
                                                                        x (
                                                                        {" " +
                                                                            object.quantity +
                                                                            " "}
                                                                        ) ชิ้น
                                                                    </p>
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <b className="h5">
                                                                        <NumberFormat
                                                                            value={parseFloat(
                                                                                object.net_price
                                                                            )}
                                                                            displayType={
                                                                                "text"
                                                                            }
                                                                            decimalScale={
                                                                                2
                                                                            }
                                                                            fixedDecimalScale={
                                                                                true
                                                                            }
                                                                            thousandSeparator={
                                                                                true
                                                                            }
                                                                            suffix={
                                                                                " บาท"
                                                                            }
                                                                        />
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                                <div className="p-2 d-flex">
                                                    <div className="form-group col-8">
                                                        <label>
                                                            รหัสส่วนลด
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={promocode}
                                                            onChange={(e) =>
                                                                setPromoCode(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <button
                                                        className="col-4 btn btn-primary my-auto"
                                                        onClick={() =>
                                                            checkPromoCode(
                                                                promocode
                                                            )
                                                        }
                                                    >
                                                        นำไปใช้
                                                    </button>
                                                </div>
                                                <div className="border-top px-4 mx-3" />
                                                <div className="p-2 d-flex pt-3">
                                                    <div className="col-8">
                                                        <b>ยอดรวมย่อย</b>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <b className="green">
                                                            <NumberFormat
                                                                value={parseFloat(
                                                                    total
                                                                )}
                                                                displayType={
                                                                    "text"
                                                                }
                                                                decimalScale={2}
                                                                fixedDecimalScale={
                                                                    true
                                                                }
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                suffix={" บาท"}
                                                            />
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex pt-3">
                                                    <div className="col-8">
                                                        <b>การจัดส่ง</b>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <b className="green">
                                                            <NumberFormat
                                                                value={parseFloat(
                                                                    shipprice
                                                                )}
                                                                displayType={
                                                                    "text"
                                                                }
                                                                decimalScale={2}
                                                                fixedDecimalScale={
                                                                    true
                                                                }
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                suffix={" บาท"}
                                                            />
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex pt-3">
                                                    <div className="col-8">
                                                        <b>ส่วนลด</b>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <b className="green">
                                                            <NumberFormat
                                                                value={parseFloat(
                                                                    discount
                                                                )}
                                                                displayType={
                                                                    "text"
                                                                }
                                                                decimalScale={2}
                                                                fixedDecimalScale={
                                                                    true
                                                                }
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                suffix={" บาท"}
                                                            />
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="border-top px-4 mx-3" />
                                                <div className="p-2 d-flex pt-3">
                                                    <div className="col-8">
                                                        <b>ยอดรวม</b>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <b className="green">
                                                            <NumberFormat
                                                                value={
                                                                    parseFloat(
                                                                        total
                                                                    ) +
                                                                    parseFloat(
                                                                        shipprice
                                                                    ) -
                                                                    parseFloat(
                                                                        discount
                                                                    )
                                                                }
                                                                displayType={
                                                                    "text"
                                                                }
                                                                decimalScale={2}
                                                                fixedDecimalScale={
                                                                    true
                                                                }
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                suffix={" บาท"}
                                                            />
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="m-3">
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            createOrder()
                                                        }
                                                        className="btn btn-feels btn-block rounded-pill"
                                                    >
                                                        ชำระเงิน
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
