import React, { useContext } from "react";

import { NavLink } from "react-router-dom";

import NumberFormat from "react-number-format";

import { CartContext } from "./contexts/CartContext";

export const CartPage = () => {
    // const [total, setTotal] = useState(0);
    const { total, cartItems, removeProduct, increase, decrease } = useContext(
        CartContext
    );
    // useEffect(() => {
    //     cartItems.map((object) =>
    //         setTotal(total + object.net_price * object.quantity)
    //     );
    // }, []);
    return (
        <div className="container">
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className="col-md-9">
                        {cartItems.length > 0 ? (
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span className="pull-right">
                                        (<strong> {cartItems.length} </strong>)
                                        รายการ
                                    </span>
                                    <h5>สินค้าในตะกร้า</h5>
                                </div>
                                <div className="ibox-content d-none d-lg-block">
                                    <div className="row">
                                        <div className="col-md-2">{""}</div>
                                        <div className="col-md-6 my-auto">
                                            สินค้า
                                        </div>
                                        <div className="col-md-2 my-auto text-center">
                                            จำนวน
                                        </div>
                                        <div className="col-md-2 my-auto">
                                            ราคาต่อชิ้น
                                        </div>
                                    </div>
                                    {cartItems.map((object, index) => {
                                        return (
                                            <div
                                                className="row my-5"
                                                key={index}
                                            >
                                                <div className="col-md-2">
                                                    {/* <div className="cart-product-imitation mx-auto"> */}
                                                    <img
                                                        src={
                                                            object
                                                                .product_image[
                                                                "0"
                                                            ]
                                                        }
                                                        className="w-100 h-100"
                                                        alt={`product-${object.product_id}`}
                                                    />
                                                    {/* </div> */}
                                                </div>
                                                <div className="col-md-6 my-auto">
                                                    <h3 className="text-navy">
                                                        {object.product_name}
                                                    </h3>
                                                </div>
                                                <div className="col-md-2 my-auto">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            {object.quantity >
                                                            1 ? (
                                                                <button
                                                                    onClick={() =>
                                                                        decrease(
                                                                            object
                                                                        )
                                                                    }
                                                                    className="btn btn-secondary"
                                                                    type="button"
                                                                >
                                                                    <i className="ki ki-minus icon-xs"></i>
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    onClick={() =>
                                                                        removeProduct(
                                                                            object
                                                                        )
                                                                    }
                                                                    className="btn btn-danger"
                                                                    type="button"
                                                                >
                                                                    <i className="flaticon2-rubbish-bin icon-xs"></i>
                                                                </button>
                                                            )}
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            value={
                                                                object.quantity
                                                            }
                                                            disabled="disabled"
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                onClick={() =>
                                                                    increase(
                                                                        object
                                                                    )
                                                                }
                                                                className="btn btn-secondary"
                                                                type="button"
                                                            >
                                                                <i className="ki ki-plus icon-xs"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 my-auto">
                                                    <h4 className="m-0">
                                                        <NumberFormat
                                                            value={parseFloat(
                                                                object.net_price
                                                            )}
                                                            displayType={"text"}
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            suffix={" บาท"}
                                                        />
                                                    </h4>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="ibox-content d-block d-lg-none">
                                    {cartItems.map((object, index) => {
                                        return (
                                            <div
                                                className="row my-5"
                                                key={index}
                                            >
                                                <div className="col-4">
                                                    {/* <div className="cart-product-imitation mx-auto"></div> */}
                                                    <img
                                                        src={
                                                            object
                                                                .product_image[
                                                                "0"
                                                            ]
                                                        }
                                                        className="w-100 h-100"
                                                        alt={`product-${object.product_id}`}
                                                    />
                                                </div>
                                                <div className="col-8 desc">
                                                    <div className="row">
                                                        <div className="col-12 my-auto">
                                                            <h3 className="text-navy">
                                                                {
                                                                    object.product_name
                                                                }
                                                            </h3>
                                                        </div>
                                                        <div className="col-12 my-auto">
                                                            <h4 className="m-0">
                                                                ราคาต่อชิ้น{" "}
                                                                <NumberFormat
                                                                    value={parseFloat(
                                                                        object.net_price
                                                                    )}
                                                                    displayType={
                                                                        "text"
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    fixedDecimalScale={
                                                                        true
                                                                    }
                                                                    thousandSeparator={
                                                                        true
                                                                    }
                                                                    suffix={
                                                                        " บาท"
                                                                    }
                                                                />
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-5">
                                                        <div className="col-12 my-auto">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    {object.quantity >
                                                                    1 ? (
                                                                        <button
                                                                            onClick={() =>
                                                                                decrease(
                                                                                    object
                                                                                )
                                                                            }
                                                                            className="btn btn-secondary"
                                                                            type="button"
                                                                        >
                                                                            <i className="ki ki-minus icon-xs"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            onClick={() =>
                                                                                removeProduct(
                                                                                    object
                                                                                )
                                                                            }
                                                                            className="btn btn-danger"
                                                                            type="button"
                                                                        >
                                                                            <i className="flaticon2-rubbish-bin icon-xs"></i>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control text-center"
                                                                    value={
                                                                        object.quantity
                                                                    }
                                                                    disabled="disabled"
                                                                />
                                                                <div className="input-group-append">
                                                                    <button
                                                                        onClick={() =>
                                                                            increase(
                                                                                object
                                                                            )
                                                                        }
                                                                        className="btn btn-secondary"
                                                                        type="button"
                                                                    >
                                                                        <i className="ki ki-plus icon-xs"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {/* <div className="ibox-content">
                                <NavLink
                                    to="/checkout"
                                    className="btn btn-primary float-right"
                                >
                                    <i className="fa fa fa-shopping-cart" />{" "}
                                    Checkout
                                </NavLink>
                                <NavLink
                                    to="/products"
                                    className="btn btn-white"
                                >
                                    <i className="fa fa-arrow-left" /> Continue
                                    shopping
                                </NavLink>
                            </div> */}
                            </div>
                        ) : (
                            // <div className="row justify-content-center align-items-center bg-white h-100">
                            //     <div className="col-12 text-center">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span className="pull-right">
                                        (<strong> {cartItems.length} </strong>)
                                        รายการ
                                    </span>
                                    <h5>สินค้าในตะกร้า</h5>
                                </div>
                                <div className="ibox-content">
                                    <div className="row justify-content-center align-items-center bg-white cart-box">
                                        <div className="col-12 text-center">
                                            <h3>คุณไม่มีสินค้าในตะกร้า</h3>
                                            <NavLink
                                                to="/products"
                                                className="btn btn-white rounded-pill"
                                            >
                                                <i className="fa fa-arrow-left" />{" "}
                                                เลือกซื้อสินค้า
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-md-3">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>สรุปค่าสินค้า</h5>
                            </div>
                            <div className="ibox-content">
                                <span>ราคารวม</span>
                                <h2 className="font-bold">
                                    <NumberFormat
                                        value={parseFloat(total)}
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        suffix={" บาท"}
                                    />
                                </h2>
                                <hr />
                                {/* <span className="text-muted small">
                                    *For United States, France and Germany
                                    applicable sales tax will be applied
                                </span> */}
                                <div className="m-t-sm">
                                    <div className="row justify-content-end">
                                        {cartItems.length > 0 && (
                                            <NavLink
                                                to="/checkout"
                                                className="btn btn-feels rounded-pill"
                                            >
                                                <i className="fa fa fa-shopping-cart" />{" "}
                                                สั่งซื้อสินค้า
                                            </NavLink>
                                        )}
                                    </div>
                                    {/* <div className="btn-group">
                                        <NavLink
                                            to="/checkout"
                                            className="btn btn-primary btn-sm"
                                        >
                                            <i className="fa fa-shopping-cart" />{" "}
                                            Checkout
                                        </NavLink>
                                        <a
                                            href="#"
                                            className="btn btn-white btn-sm"
                                        >
                                            {" "}
                                            Cancel
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="ibox">
                            <div className="ibox-title">
                                <h5>Support</h5>
                            </div>
                            <div className="ibox-content text-center">
                                <h3>
                                    <i className="fa fa-phone" /> +43 100 783
                                    001
                                </h3>
                                <span className="small">
                                    Please contact with us if you have any
                                    questions. We are avalible 24h.
                                </span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

        // <div className="row">
        //     <div className="col-md-12 col-sm-12">
        //         <div className="goods-data clearfix mx-0 mx-md-15">
        //             <div className="table-wrapper-responsive">
        //                 <table summary="Shopping cart">
        //                     <tbody>
        //                         <tr>
        //                             <th className="goods-page-image">Image</th>
        //                             <th className="goods-page-description">
        //                                 Description
        //                             </th>
        //                             <th className="goods-page-ref-no">
        //                                 Ref No
        //                             </th>
        //                             <th className="goods-page-quantity">
        //                                 Quantity
        //                             </th>
        //                             <th className="goods-page-price">
        //                                 Unit price
        //                             </th>
        //                             <th
        //                                 className="goods-page-total"
        //                                 colSpan={2}
        //                             >
        //                                 Total
        //                             </th>
        //                         </tr>
        //                         <tr>
        //                             <td className="goods-page-image">
        //                                 <a href="javascript:;">
        //                                     <img
        //                                         src="assets/pages/img/products/model3.jpg"
        //                                         alt="Berry Lace Dress"
        //                                     />
        //                                 </a>
        //                             </td>
        //                             <td className="goods-page-description">
        //                                 <h3>
        //                                     <a href="javascript:;">
        //                                         Cool green dress with red bell
        //                                     </a>
        //                                 </h3>
        //                                 <p>
        //                                     <strong>Item 1</strong> - Color:
        //                                     Green; Size: S
        //                                 </p>
        //                                 <em>More info is here</em>
        //                             </td>
        //                             <td className="goods-page-ref-no">
        //                                 javc2133
        //                             </td>
        //                             <td className="goods-page-quantity">
        //                                 <div className="product-quantity">
        //                                     <div className="input-group bootstrap-touchspin input-group-sm">
        //                                         <span className="input-group-btn">
        //                                             <button
        //                                                 className="btn quantity-down bootstrap-touchspin-down"
        //                                                 type="button"
        //                                             >
        //                                                 <i className="fa fa-angle-down" />
        //                                             </button>
        //                                         </span>
        //                                         <span
        //                                             className="input-group-addon bootstrap-touchspin-prefix"
        //                                             style={{ display: "none" }}
        //                                         />
        //                                         <input
        //                                             id="product-quantity"
        //                                             type="text"
        //                                             defaultValue={1}
        //                                             readOnly
        //                                             className="form-control input-sm"
        //                                             style={{ display: "block" }}
        //                                         />
        //                                         <span
        //                                             className="input-group-addon bootstrap-touchspin-postfix"
        //                                             style={{ display: "none" }}
        //                                         />
        //                                         <span className="input-group-btn">
        //                                             <button
        //                                                 className="btn quantity-up bootstrap-touchspin-up"
        //                                                 type="button"
        //                                             >
        //                                                 <i className="fa fa-angle-up" />
        //                                             </button>
        //                                         </span>
        //                                     </div>
        //                                 </div>
        //                             </td>
        //                             <td className="goods-page-price">
        //                                 <strong>
        //                                     <span>$</span>47.00
        //                                 </strong>
        //                             </td>
        //                             <td className="goods-page-total">
        //                                 <strong>
        //                                     <span>$</span>47.00
        //                                 </strong>
        //                             </td>
        //                             <td className="del-goods-col">
        //                                 <a
        //                                     className="del-goods"
        //                                     href="javascript:;"
        //                                 >
        //                                     &nbsp;
        //                                 </a>
        //                             </td>
        //                         </tr>
        //                         <tr>
        //                             <td className="goods-page-image">
        //                                 <a href="javascript:;">
        //                                     <img
        //                                         src="assets/pages/img/products/model4.jpg"
        //                                         alt="Berry Lace Dress"
        //                                     />
        //                                 </a>
        //                             </td>
        //                             <td className="goods-page-description">
        //                                 <h3>
        //                                     <a href="javascript:;">
        //                                         Cool green dress with red bell
        //                                     </a>
        //                                 </h3>
        //                                 <p>
        //                                     <strong>Item 1</strong> - Color:
        //                                     Green; Size: S
        //                                 </p>
        //                                 <em>More info is here</em>
        //                             </td>
        //                             <td className="goods-page-ref-no">
        //                                 javc2133
        //                             </td>
        //                             <td className="goods-page-quantity">
        //                                 <div className="product-quantity">
        //                                     <div className="input-group bootstrap-touchspin input-group-sm">
        //                                         <span className="input-group-btn">
        //                                             <button
        //                                                 className="btn quantity-down bootstrap-touchspin-down"
        //                                                 type="button"
        //                                             >
        //                                                 <i className="fa fa-angle-down" />
        //                                             </button>
        //                                         </span>
        //                                         <span
        //                                             className="input-group-addon bootstrap-touchspin-prefix"
        //                                             style={{ display: "none" }}
        //                                         />
        //                                         <input
        //                                             id="product-quantity2"
        //                                             type="text"
        //                                             defaultValue={1}
        //                                             readOnly
        //                                             className="form-control input-sm"
        //                                             style={{ display: "block" }}
        //                                         />
        //                                         <span
        //                                             className="input-group-addon bootstrap-touchspin-postfix"
        //                                             style={{ display: "none" }}
        //                                         />
        //                                         <span className="input-group-btn">
        //                                             <button
        //                                                 className="btn quantity-up bootstrap-touchspin-up"
        //                                                 type="button"
        //                                             >
        //                                                 <i className="fa fa-angle-up" />
        //                                             </button>
        //                                         </span>
        //                                     </div>
        //                                 </div>
        //                             </td>
        //                             <td className="goods-page-price">
        //                                 <strong>
        //                                     <span>$</span>47.00
        //                                 </strong>
        //                             </td>
        //                             <td className="goods-page-total">
        //                                 <strong>
        //                                     <span>$</span>47.00
        //                                 </strong>
        //                             </td>
        //                             <td className="del-goods-col">
        //                                 <a
        //                                     className="del-goods"
        //                                     href="javascript:;"
        //                                 >
        //                                     &nbsp;
        //                                 </a>
        //                             </td>
        //                         </tr>
        //                     </tbody>
        //                 </table>
        //             </div>
        //             <div className="shopping-total">
        //                 <ul>
        //                     <li>
        //                         <em>Sub total</em>
        //                         <strong className="price">
        //                             <span>$</span>47.00
        //                         </strong>
        //                     </li>
        //                     <li>
        //                         <em>Shipping cost</em>
        //                         <strong className="price">
        //                             <span>$</span>3.00
        //                         </strong>
        //                     </li>
        //                     <li className="shopping-total-price">
        //                         <em>Total</em>
        //                         <strong className="price">
        //                             <span>$</span>50.00
        //                         </strong>
        //                     </li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="d-flex justify-content-end">
        //         <button className="btn btn-primary" type="submit">
        //             Checkout <i className="fa fa-check"></i>
        //         </button>
        //     </div>
        // </div>
    );
};
