import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const HomePage = () => {
    const [index, setIndex] = useState(0);
    // const [direction, setDirection] = useState(null);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
            <div className="w-100 d-none d-lg-block">
                <img
                    src={toAbsoluteUrl("/media/banners/homepage-2.jpg")}
                    className="w-100"
                    alt="homepage-banner"
                />
            </div>
            <div className="w-100 d-block d-lg-none">
                <img
                    src={toAbsoluteUrl("/media/banners/homepage-mobile-2.jpg")}
                    className="w-100"
                    alt="homepage-banner-mobile"
                />
            </div>
            {/* <Carousel activeIndex={index} onSelect={handleSelect}> */}
            {/* <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={toAbsoluteUrl("/media/banners/homepage.jpg")}
                        alt="First slide"
                    />
                </Carousel.Item> */}
            {/* <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bbcbfb1a9%20text%20%7B%20fill%3A%23ffffff%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bbcbfb1a9%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23282c34%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3125%22%20y%3D%22217.7609375%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bbcbfb1aa%20text%20%7B%20fill%3A%23ffffff%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bbcbfb1aa%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%2320232a%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22217.7609375%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bbcbfb1aa%20text%20%7B%20fill%3A%23ffffff%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bbcbfb1aa%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%2320232a%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22217.7609375%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bbcbfb1aa%20text%20%7B%20fill%3A%23ffffff%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bbcbfb1aa%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%2320232a%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22217.7609375%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bbcbfb1aa%20text%20%7B%20fill%3A%23ffffff%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bbcbfb1aa%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%2320232a%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22217.7609375%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                        alt="Third slide"
                    />
                </Carousel.Item> */}
            {/* </Carousel> */}
            {/* <section id="cliens" className="cliens section-bg">
                <div className="container-fluid">
                    <div className="row" data-aos="zoom-in">
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img
                                src={toAbsoluteUrl(
                                    "/media/parner-logo/Xiaomi-Logo.png"
                                )}
                                className="img-fluid"
                                alt="xiaomi-logo"
                            />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img
                                src="assets/img/clients/client-2.png"
                                className="img-fluid"
                                alt
                            />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img
                                src="assets/img/clients/client-3.png"
                                className="img-fluid"
                                alt
                            />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img
                                src="assets/img/clients/client-4.png"
                                className="img-fluid"
                                alt
                            />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img
                                src="assets/img/clients/client-5.png"
                                className="img-fluid"
                                alt
                            />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                            <img
                                src="assets/img/clients/client-6.png"
                                className="img-fluid"
                                alt
                            />
                        </div>
                    </div>
                </div>
            </section> */}
            <div className="footer-newsletter bg-feels">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            {/* <h4>Join Our Newsletter</h4> */}
                            <p>
                                พบกับสินค้ามากมายหลากหลายเพียงนิ้วคลิกที่นี่ได้เลย
                            </p>
                            <NavLink
                                to="/products"
                                className=" btn btn-lg btn-white text-dark rounded-pill font-size-h2"
                            >
                                เลือกซื้อเลย
                            </NavLink>
                            {/* <form action method="post">
                                <input type="email" name="email" />
                                <input type="submit" defaultValue="Subscribe" />
                            </form> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div
                id="store"
                className="row w-100 justify-content-center m-0 bg-circle-store"
            >
                <div className="col-12 row align-items-center justify-content-center p-0 vh-store-100">
                    <div className="d-flex justify-content-center wh-50vhw p-md-5 p-2 align-items-center">
                        <div className="rounded-circle p-5 p-lg-0 circle-store justify-content-center transition w-100 h-100 row m-0 align-content-center flex-wrap">
                            <h1 className="m-0 fs-100px col-12 text-center elegant-color-text line-h-100">
                                Feels Store
                            </h1>
                            <div className="col-12 elegant-color-text text-center pt-4 pt-lg-5">
                                <p className="ng-tns-c77-0">
                                    พบกับสินค้ามากมายหลากหลายเพียงนิ้วคลิกที่
                                    Feels Store
                                </p>
                            </div>
                            <div className="col12 mt-3">
                                <button
                                    type="button"
                                    className="btn-white elegant-color-text rounded-pill btn"
                                >
                                    เลือกซื้อเลย
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};
