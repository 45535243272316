import React, { useState, useEffect, useContext } from "react";

import { Carousel } from "react-bootstrap";
// import { useSubheader } from "../../_metronic/layout";

// import { toAbsoluteUrl } from "../../_metronic/_helpers";

import { useLocation, useHistory } from "react-router-dom";

import NumberFormat from "react-number-format";

import { CartContext } from "./contexts/CartContext";

import environment from "../environments/environment";

export const ProductDetailPage = () => {
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [index, setIndex] = useState(0);
    const [quantity, setQuantity] = useState(1);
    // const [direction, setDirection] = useState(null);
    const [productdetail, setProductDetail] = useState({});
    const [imagelist, setImageList] = useState([]);
    const [detailtab1, setDetailTab1] = useState([]);
    const [detailtab2, setDetailTab2] = useState({});
    const [tab, setTab] = useState("1");

    const { addProduct } = useContext(CartContext);

    useEffect(() => {
        // console.log(location.state);
        if (location.state !== undefined) {
            let productid = location.state.product_id.toString();
            getProductDetail(productid);
        } else {
            history.push("/index");
        }
    }, []);

    const getProductDetail = (productid) => {
        fetch(environment.url + environment.version + "/feelsshop/getproduct", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: environment.secret,
            },
            body: JSON.stringify({
                products_id: [productid],
                options: ["detail"],
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === "0") {
                    setProductDetail(Object.values(result.result)[0]);
                    setImageList(
                        Object.values(
                            Object.values(result.result)[0].product_image
                        )
                    );
                    // setImageList(imagelist.shift());
                    setDetailTab1(
                        Object.values(
                            Object.values(result.result)[0].detail.attribute
                        )
                    );
                    setDetailTab2(
                        Object.values(result.result)[0].detail.specification
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
            });
    };

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    // const suhbeader = useSubheader();
    // suhbeader.setTitle("Xiaomi");
    // console.log(typeof productdetail[0]);
    // console.log(detailtab1);
    // console.log(imagelist);
    return (
        <>
            {!loading ? (
                <>
                    <div className="main" id="product-detail-page">
                        <div className="container p-10 h-100">
                            {!error ? (
                                <div className="row rounded-top-3 h-100 bg-white p-10">
                                    <div className="col-md-5">
                                        <div className="row align-items-center h-100">
                                            <Carousel
                                                activeIndex={index}
                                                onSelect={handleSelect}
                                            >
                                                {imagelist.length > 0 ? (
                                                    imagelist.map(
                                                        (object, index) => {
                                                            return (
                                                                <Carousel.Item
                                                                    key={index}
                                                                >
                                                                    <img
                                                                        className="d-block w-100"
                                                                        src={
                                                                            object
                                                                        }
                                                                        alt="First slide"
                                                                    />
                                                                </Carousel.Item>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bbcbfb1a8%20text%20%7B%20fill%3A%23ffffff%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bbcbfb1a8%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22217.7609375%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                                                            alt="First slide"
                                                        />
                                                    </Carousel.Item>
                                                )}
                                            </Carousel>
                                        </div>
                                    </div>
                                    <div
                                        className="col-md-7 p-10"
                                        id="product-detail"
                                    >
                                        <h1 className="text-center">
                                            {productdetail.product_name}
                                        </h1>
                                        <div className="price-availability-block clearfix p-5">
                                            <div className="row justify-content-between">
                                                <div className="price">
                                                    <strong>
                                                        <span>ราคา</span>
                                                    </strong>
                                                </div>
                                                <div className="price">
                                                    <strong>
                                                        <NumberFormat
                                                            value={parseFloat(
                                                                productdetail.net_price
                                                            )}
                                                            displayType={"text"}
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            suffix={" บาท"}
                                                        />
                                                    </strong>
                                                    {/* <em>
                                                    $<span>62.00</span>
                                                </em> */}
                                                </div>
                                            </div>

                                            {/* <div className="availability">
                                                Availability:{"{"}" "{"}"}
                                                <strong>In Stock</strong>
                                            </div> */}
                                        </div>
                                        <div className="menu button-content pt-3 pb-3">
                                            <div className="d-flex rounded-pill grey lighten-4">
                                                <div
                                                    className={`sub-menu rounded-pill transition-2 pt-2 pb-2 ${
                                                        tab === "1" && "active"
                                                    }`}
                                                    onClick={() => setTab("1")}
                                                >
                                                    <p className="m-0 cursor-pointer">
                                                        คุณสมบัติ
                                                    </p>
                                                </div>
                                                <div
                                                    className={`sub-menu rounded-pill transition-2 pt-2 pb-2 ${
                                                        tab === "2" && "active"
                                                    }`}
                                                    onClick={() => setTab("2")}
                                                >
                                                    <p className="m-0 cursor-pointer">
                                                        รายละเอียดสินค้า
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`content ng-star-inserted p-md-10 p-5 ${
                                                tab === "1"
                                                    ? "d-block"
                                                    : "d-none"
                                            }`}
                                        >
                                            {productdetail.description !==
                                                null && (
                                                <p>
                                                    {productdetail.description}
                                                </p>
                                            )}
                                            <h1 className="text-light-green-main ng-star-inserted">
                                                คุณสมบัติ
                                            </h1>
                                            <ul className="pl-md-15 pl-0">
                                                {detailtab1.map(
                                                    (object, index) => {
                                                        return (
                                                            <li
                                                                className="ng-star-inserted"
                                                                key={index}
                                                            >
                                                                {object}
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                        <div
                                            className={`content ng-star-inserted p-md-10 p-5 ${
                                                tab === "2"
                                                    ? "d-block"
                                                    : "d-none"
                                            }`}
                                        >
                                            <h1 className="text-light-green-main ng-star-inserted">
                                                รายละเอียดสินค้า
                                            </h1>
                                            {Object.keys(detailtab2).map(
                                                (object, index) => {
                                                    return (
                                                        <p
                                                            className="ng-star-inserted"
                                                            key={index}
                                                        >
                                                            {object +
                                                                " : " +
                                                                detailtab2[
                                                                    object
                                                                ]}
                                                        </p>
                                                    );
                                                }
                                            )}
                                        </div>
                                        <div className="row pl-md-10 pl-5 pr-md-10 pr-5">
                                            <div className="col-md-2 my-auto">
                                                จำนวน
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group my-auto">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <button
                                                                onClick={() =>
                                                                    setQuantity(
                                                                        quantity -
                                                                            1
                                                                    )
                                                                }
                                                                disabled={
                                                                    quantity ===
                                                                    1
                                                                }
                                                                className={`btn ${
                                                                    quantity ===
                                                                    1
                                                                        ? "btn-danger"
                                                                        : "btn-secondary"
                                                                }`}
                                                                type="button"
                                                            >
                                                                <i className="ki ki-minus icon-xs"></i>
                                                            </button>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            value={quantity}
                                                            onKeyPress={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    !/[0-9]/.test(
                                                                        event.key
                                                                    )
                                                                ) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                setQuantity(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                onClick={() =>
                                                                    setQuantity(
                                                                        quantity +
                                                                            1
                                                                    )
                                                                }
                                                                className="btn btn-secondary"
                                                                type="button"
                                                            >
                                                                <i className="ki ki-plus icon-xs"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6 my-auto">
                                                มีสินค้าทั้งหมด 44204 ชิ้น
                                            </div> */}
                                        </div>
                                        <div className="mt-5 p-5">
                                            <div className="row justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary rounded-pill"
                                                    onClick={() =>
                                                        addProduct({
                                                            ...productdetail,
                                                            quantity: quantity,
                                                        })
                                                    }
                                                >
                                                    <i className="flaticon2-supermarket"></i>
                                                    <span>เพิ่มลงตะกร้า</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row justify-content-center align-items-center bg-white rounded-top-3 h-100">
                                    <div className="col-12 text-center">
                                        <i className="fas fa-box-open fa-5x text-dark"></i>
                                        <h3 className="mt-5">
                                            ไม่สามารถดึงข้อมูลสินค้าได้
                                            กรุณาลองใหม่อีกครั้ง
                                        </h3>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
