import React, { useState, useEffect, useRef } from "react";

import { useLocation, useHistory } from "react-router-dom";

import QRCodeReact from "qrcode.react";

import NumberFormat from "react-number-format";

import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const OrderResultPage = () => {
    const location = useLocation();
    const history = useHistory();
    const downloadRef = useRef(null);
    const [responsecode, setResponsecode] = useState(0);
    const [qrcode, setQrcode] = useState("");
    const [result, setResult] = useState({});
    useEffect(() => {
        if (location.state !== undefined) {
            let rawresult = location.state.result;
            if (rawresult.responsecode === "0") {
                setResponsecode(rawresult.responsecode);
                setQrcode(rawresult.result.qrcode["0"]);
                setResult(rawresult.result);
            } else {
                setResponsecode(rawresult.responsecode);
            }
        } else {
            history.push("/index");
        }
    }, []);

    const download = (ordernumber) => {
        console.log(downloadRef);
        const canvas: any = document.querySelector(".qrimg > canvas");
        console.log(canvas);
        downloadRef.current.href = canvas.toDataURL();
        downloadRef.current.download = `${ordernumber}.png`;
        // downloadRef.download = `${ordernumber}.png`;
        // downloadRef.download = `test.png`;
    };
    // console.log(qrcode);
    // console.log(result);
    return (
        <div className="d-flex flex-column-fluid">
            {/*begin::Container*/}
            <div className="container">
                {/* begin::Card*/}
                <div className="card card-custom overflow-hidden">
                    <div className="card-body p-0">
                        {responsecode === "0" ? (
                            <>
                                <div
                                    className="bg-feels row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-15 px-md-0"
                                    // style={{
                                    //     backgroundImage:
                                    //         "url(/metronic/theme/html/demo5/dist/assets/media/bg/bg-6.jpg)",
                                    // }}
                                >
                                    <div className="col-md-9">
                                        <div className="d-flex justify-content-between flex-column flex-md-row">
                                            <h1 className="display-4 text-white font-weight-boldest my-auto">
                                                คำสั่งซื้อ #{result.ordernumber}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                    <div className="col-md-9">
                                        <div className="row justify-content-center">
                                            <div className="text-center rounded">
                                                <div className="text-center rounded bg-feels p-3">
                                                    <div className="qrimg">
                                                        <QRCodeReact
                                                            value={qrcode}
                                                            size={256}
                                                            id="qrcode"
                                                        />
                                                    </div>
                                                    <div className="justify-content-center">
                                                        <a
                                                            ref={downloadRef}
                                                            // href={"#qrcode"}
                                                            className="text-white text-center"
                                                            onClick={() =>
                                                                download(
                                                                    result.ordernumber
                                                                )
                                                            }
                                                        >
                                                            บันทึกภาพ
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-10 text-center">
                                                <p className="font-size-h3">
                                                    ราคารวม{" "}
                                                    <NumberFormat
                                                        value={parseFloat(
                                                            result.total
                                                        )}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        suffix={" บาท"}
                                                    />
                                                </p>{" "}
                                                <p className="text-center">
                                                    กรุณาบันทึกภาพคิวอาร์โค้ดและทำการชำระเงินที่แอพ
                                                    <span> ธนาคาร </span>
                                                    ของท่าน{" "}
                                                    {/* <b className="text-danger">
                                                    ภายใน 15 วัน{" "}
                                                </b> */}
                                                    เมื่อชำระเงินเรียบร้อยแล้วรอรับสินค้าที่บ้านท่านได้เลย{" "}
                                                </p>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div>test</div>
                        )}
                    </div>
                </div>
            </div>
        </div>

        // <div className="row align-items-center p-0 m-0 min-h-100">
        //     <section className="section p-4 col p-0">
        //         <div className="container p-0 mb-5 mb-md-0">
        //             <div className="row m-0 align-items-center">
        //                 <div className="col-sm-12">
        //                     <div className="row justify-content-center align-items-center">
        //                         <div className="col-lg-10 col-sm-12 bg-white rounded-top-3 rounded-bottom-3 p-3">
        //                             <div className="row">
        //                                 <div className="col-sm-12 p-0">
        //                                     <div className="row justify-content-center col-sm-12 m-0">
        //                                         <div className="col-lg-8 col-sm-12">
        //                                             <>
        //                                                 <div className="col-12">
        //                                                     <div className="os-header__heading">
        //                                                         <span className="os-order-number">
        //                                                             คำสั่งซื้อ
        //                                                             #166241
        //                                                         </span>
        //                                                         <h2
        //                                                             className="os-header__title"
        //                                                             id="main-header"
        //                                                             tabIndex={
        //                                                                 -1
        //                                                             }
        //                                                         >
        //                                                             ขอขอบคุณ
        //                                                             กรณศักดิ์!
        //                                                         </h2>
        //                                                     </div>
        //                                                 </div>
        //                                                 <div className="col-12">
        //                                                     <div className="row justify-content-center">
        //                                                         <div className="text-center rounded ">
        //                                                             <div className="text-center rounded bg-bill-pic p-1">
        //                                                                 <div className="qrimg">
        //                                                                     <QRCodeReact
        //                                                                         value={
        //                                                                             this
        //                                                                                 .state
        //                                                                                 .orderqrcode
        //                                                                         }
        //                                                                         size={
        //                                                                             256
        //                                                                         }
        //                                                                         id="qrcode"
        //                                                                     />
        //                                                                 </div>
        //                                                                 <div className="justify-content-center">
        //                                                                     <a
        //                                                                         href={
        //                                                                             "#qrcode"
        //                                                                         }
        //                                                                         ref={(
        //                                                                             ref: any
        //                                                                         ): any =>
        //                                                                             (this.downloadRef = ref)
        //                                                                         }
        //                                                                         className="text-white text-center"
        //                                                                         onClick={
        //                                                                             this
        //                                                                                 .download
        //                                                                         }
        //                                                                     >
        //                                                                         บันทึกภาพ
        //                                                                     </a>
        //                                                                 </div>
        //                                                             </div>

        //                                                             <div ref={this.componentRef} style={{width:400, height:250,padding:"1rem"}}>
        //                                                                                     <div className="qrimg">
        //                                                                                         <QRCodeReact value={this.state.orderqrcode}  id="qrcode" style={{width:150, height:150}} />
        //                                                                                         <h5 className="text-center text-color-main mb-0">
        //                                                                                             คำสั่งซื้อ {this.state.ordernumber}<br />
        //                                                                                             เบอร์ที่สั่งซื้อ {phonenumber}
        //                                                                                         </h5>
        //                                                                                     </div>
        //                                                                                 </div>
        //                                                                                 <Button
        //                                                                                     color="outline-warning"
        //                                                                                     type="submit"><a
        //                                                                                     className="btn-save"
        //                                                                                     href={"#qrcode"}
        //                                                                                     onClick={this.saveqrcode}
        //                                                                                 >
        //                                                                                     บันทึกภาพ
        //                                                                                 </a></Button>
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                                 <div className="col-12 mt-3 text-center">
        //                                                     <p className="text-center">
        //                                                         กรุณาบันทึกภาพคิวอาร์โค้ดและทำการชำระเงินที่แอพ
        //                                                         <span>
        //                                                             {" "}
        //                                                             ธนาคาร{" "}
        //                                                         </span>
        //                                                         ของท่าน{" "}
        //                                                         <b className="text-danger">
        //                                                             ภายใน 15 วัน{" "}
        //                                                         </b>
        //                                                         เมื่อชำระเงินเรียบร้อยแล้วรอรับซิม
        //                                                         Feels
        //                                                         ที่บ้านได้เลย
        //                                                     </p>
        //                                                 </div>
        //                                             </>
        //                                             <div className="row justify-content-center">
        //                                                 <div className="col-sm-12 text-center row justify-content-center">
        //                                                     <button
        //                                                         type="submit"
        //                                                         className="btn btn-warning col-md-4 col-sm-6 mt-3 mt-md-0 "
        //                                                         onClick={
        //                                                             this.onBack
        //                                                         }
        //                                                     >
        //                                                         กลับไปที่หน้าแรก
        //                                                     </button>
        //                                                     <Button
        //                                                         color="warning"
        //                                                         type="submit"
        //                                                         className={
        //                                                             "col-md-4 col-sm-6 mt-3 mt-md-0 " +
        //                                                             (this.state
        //                                                                 .result &&
        //                                                             this.state
        //                                                                 .responsecode ===
        //                                                                 "0"
        //                                                                 ? ""
        //                                                                 : "d-none")
        //                                                         }
        //                                                         onClick={
        //                                                             this
        //                                                                 .handleClick
        //                                                         }
        //                                                     >
        //                                                         เรียกดูใบสั่งซื้อ
        //                                                     </Button>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </section>
        // </div>
    );
};
