import React, { useContext, useEffect, useState } from "react";

// import * as cart from "./_redux/cartRedux";

// import { connect } from "react-redux";

// import { injectIntl } from "react-intl";

import {
    // NavLink,
    useHistory,
} from "react-router-dom";

// import { Aside } from "../../_metronic/layout/components/aside/Aside";

import { toAbsoluteUrl } from "../../_metronic/_helpers";

import { CartContext } from "./contexts/CartContext";

import NumberFormat from "react-number-format";

import environment from "../environments/environment";

// export default function ProductsPage() {
export const ProductsPage = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [products, setProducts] = useState([]);
    const { addProduct, cartItems, increase } = useContext(CartContext);
    const isInCart = (product) => {
        return !!cartItems.find(
            (item) => item.product_id === product.product_id
        );
    };

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = () => {
        fetch(environment.url + environment.version + "/feelsshop/getproduct", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: environment.secret,
            },
            body: JSON.stringify({}),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === "0") {
                    setProducts(Object.values(result.result));
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
            });
    };

    const viewProduct = (productid) => {
        history.push({
            pathname: "/product/item",
            state: { product_id: productid },
        });
        // sessionStorage.setItem("product", productid);
    };

    return (
        <>
            {!loading ? (
                <>
                    <div className="w-100 d-none d-lg-block">
                        <img
                            src={toAbsoluteUrl("/media/banners/products.png")}
                            className="w-100"
                            alt="product-banner"
                        />
                    </div>
                    <div className="w-100 d-block d-lg-none">
                        <img
                            src={toAbsoluteUrl(
                                "/media/banners/products-mobile.png"
                            )}
                            className="w-100"
                            alt="product-banner-mobile"
                        />
                    </div>
                    <div className="main">
                        <div
                            className={`container p-10 ${
                                error || products.length === 0 ? "h-500-px" : ""
                            }`}
                        >
                            {!error ? (
                                <div
                                    className={`row margin-bottom-40 ${
                                        products.length === 0 ? "h-100" : ""
                                    }`}
                                >
                                    {/* <div className="sidebar col-md-3 col-sm-5">
                            <Aside />
                        </div> */}
                                    <div className="col-md-12 col-sm-12">
                                        {products.length > 0 ? (
                                            <div className="row product-list">
                                                {products.map(
                                                    (object, index) => {
                                                        // console.log(object);
                                                        return (
                                                            <div
                                                                className="col-md-4 col-sm-6 col-xs-12"
                                                                key={index}
                                                            >
                                                                <div className="product-item">
                                                                    <div className="pi-img-wrapper">
                                                                        {Object.keys(
                                                                            object.product_image
                                                                        )
                                                                            .length >
                                                                        0 ? (
                                                                            <img
                                                                                src={
                                                                                    object
                                                                                        .product_image[
                                                                                        "0"
                                                                                    ]
                                                                                }
                                                                                className="img-responsive h-100 w-100"
                                                                                alt={`product-${object.product_id}`}
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src="assets/pages/img/products/model1.jpg"
                                                                                className="img-responsive"
                                                                                alt={`product-${object.product_id}`}
                                                                            />
                                                                        )}
                                                                        <div>
                                                                            <button
                                                                                onClick={() =>
                                                                                    viewProduct(
                                                                                        object.product_id
                                                                                    )
                                                                                }
                                                                                // to="/product/item"
                                                                                className="btn btn-default fancybox-fast-view rounded-pill"
                                                                            >
                                                                                รายละเอียด
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <h3>
                                                                        <div className="font-size-h6">
                                                                            {
                                                                                object.product_name
                                                                            }
                                                                        </div>
                                                                    </h3>
                                                                    <div className="pi-price">
                                                                        <NumberFormat
                                                                            value={parseFloat(
                                                                                object.net_price
                                                                            )}
                                                                            displayType={
                                                                                "text"
                                                                            }
                                                                            decimalScale={
                                                                                2
                                                                            }
                                                                            fixedDecimalScale={
                                                                                true
                                                                            }
                                                                            thousandSeparator={
                                                                                true
                                                                            }
                                                                            suffix={
                                                                                " บาท"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {!isInCart(
                                                                        object
                                                                    ) && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                addProduct(
                                                                                    object
                                                                                )
                                                                            }
                                                                            className="btn btn-default add2cart rounded-pill"
                                                                        >
                                                                            เพิ่มไปยังตะกร้า
                                                                        </button>
                                                                    )}
                                                                    {isInCart(
                                                                        object
                                                                    ) && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                increase(
                                                                                    object
                                                                                )
                                                                            }
                                                                            className="btn btn-default add2cart rounded-pill"
                                                                        >
                                                                            เพิ่มจำนวนสินค้า
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        ) : (
                                            <div className="row justify-content-center align-items-center bg-white h-100">
                                                <div className="col-12 text-center">
                                                    <i className="fas fa-box-open fa-5x text-dark"></i>
                                                    <h3 className="mt-5">
                                                        ไม่มีสินค้าที่พร้อมให้บริการ
                                                        ขณะนี้
                                                    </h3>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="row justify-content-center align-items-center bg-white h-100">
                                    <div className="col-12 text-center">
                                        <i className="fas fa-box-open fa-5x text-dark"></i>
                                        <h3 className="mt-5">
                                            ไม่สามารถดึงข้อมูลสินค้าได้
                                            กรุณาลองใหม่อีกครั้ง
                                        </h3>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

// export default injectIntl(connect(null, cart.actions)(ProductsPage));
