/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react"; //useState,
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    Dropdown, // OverlayTrigger,
    //Tooltip,
    Badge,
} from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { NavLink } from "react-router-dom";
import { CartContext } from "../../../../../app/pages/contexts/CartContext";
import NumberFormat from "react-number-format";

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};

export function MyCartDropdown() {
    // const [cart, setCart] = useState([]);
    const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
    const {
        cartItems,
        itemCount,
        increase,
        decrease,
        removeProduct,
    } = useContext(CartContext);
    return (
        <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
                as={DropdownTopbarItemToggler}
                id="dropdown-toggle-my-cart-toggle"
            >
                {/* <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip id="mycart-panel-tooltip">
                            ตะกร้าสินค้า
                        </Tooltip>
                    }
                > */}
                {cartItems.length === 0 ? (
                    <div className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1">
                        {/* <div className="btn p-0 ml-4"> */}
                        <span className="svg-icon svg-icon-xl">
                            <SVG
                                title="ตะกร้าสินค้า"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Shopping/Cart3.svg"
                                )}
                            />
                            {/* <i className="symbol-badge bg-danger"></i> */}
                        </span>
                    </div>
                ) : (
                    <div className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 text-center position-relative">
                        {/* <div className="btn p-0 ml-4 text-center"> */}
                        <span className="svg-icon svg-icon-xl">
                            <SVG
                                title="ตะกร้าสินค้า"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Shopping/Cart3.svg"
                                )}
                            />
                        </span>
                        <span className="label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1">
                            {cartItems.length}
                        </span>
                        {/* <span className="ml-5 label label-xl font-weight-boldest label-rounded label-danger mx-auto">
                            {cartItems.length}
                        </span> */}
                    </div>
                )}
                {/* </OverlayTrigger> */}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-xl dropdown-menu-anim-up">
                <form>
                    <div
                        className="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
                        style={{ backgroundImage: `url(${bgImage})` }}
                    >
                        <span className="btn btn-md btn-icon bg-white-o-15 mr-4 cursor-default">
                            <i className="flaticon2-shopping-cart-1 text-success"></i>
                        </span>
                        <h4 className="text-white m-0 flex-grow-1 mr-3">
                            ตะกร้าสินค้า
                        </h4>
                        <h6 className="my-auto">
                            <Badge variant="success">
                                {cartItems.length} รายการ
                            </Badge>
                        </h6>
                        {/* <div className="btn btn-success btn-sm">2 Items</div> */}
                    </div>
                    {itemCount > 0 ? (
                        <PerfectScrollbar
                            options={perfectScrollbarOptions}
                            className="scroll"
                            style={{ maxHeight: "35vh", position: "relative" }}
                        >
                            {cartItems.map((object, index) => {
                                return (
                                    <div key={index}>
                                        <div className="d-flex align-items-center justify-content-between p-8">
                                            <div className="d-flex flex-column mr-2">
                                                {/* <p className="font-weight-bold text-dark-75 font-size-lg text-hover-primary"> */}
                                                <p className="font-weight-bold text-dark-75 font-size-lg">
                                                    {object.product_name}
                                                </p>
                                                {/* <span className="text-muted">
                                                    Best kichen badge in 2020
                                                </span> */}
                                                <div className="d-flex align-items-center mt-2">
                                                    <span className="font-weight-bold mr-1 text-dark-75 font-size-3">
                                                        <NumberFormat
                                                            value={parseFloat(
                                                                object.net_price
                                                            )}
                                                            displayType={"text"}
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            suffix={" บาท"}
                                                        />
                                                    </span>
                                                    <span className="text-muted mr-1">
                                                        จำนวน
                                                    </span>
                                                    <span className="font-weight-bold mr-2 text-dark-75 font-size-3">
                                                        {object.quantity}
                                                    </span>
                                                    {object.quantity > 1 ? (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                decrease(object)
                                                            }
                                                            className="btn btn-xs btn-light-success btn-icon mr-2"
                                                        >
                                                            <i className="ki ki-minus icon-xs"></i>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                removeProduct(
                                                                    object
                                                                )
                                                            }
                                                            className="btn btn-xs btn-light-danger btn-icon mr-2"
                                                        >
                                                            <i className="flaticon2-rubbish-bin icon-xs"></i>
                                                        </button>
                                                    )}
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            increase(object)
                                                        }
                                                        className="btn btn-xs btn-light-success btn-icon"
                                                    >
                                                        <i className="ki ki-plus icon-xs"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="symbol symbol-70 flex-shrink-0">
                                                <img
                                                    src={
                                                        object.product_image[
                                                            "0"
                                                        ]
                                                    }
                                                    alt={`product-${object.product_id}`}
                                                />
                                            </div>
                                        </div>
                                        {index !== cartItems.length && (
                                            <div className="separator separator-solid"></div>
                                        )}
                                    </div>
                                );
                            })}
                        </PerfectScrollbar>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center py-30">
                            <div className="col-12 text-center">
                                <h3>คุณไม่มีสินค้าในตะกร้า</h3>
                                <NavLink
                                    to="/products"
                                    className="btn btn-feels rounded-pill text-weight-bold"
                                >
                                    เลือกซื้อสินค้า
                                </NavLink>
                            </div>
                        </div>
                    )}

                    <div className="p-8">
                        {/* <div className="d-flex align-items-center justify-content-between mb-4">
                            <span className="font-weight-bold text-muted font-size-sm mr-2">
                                Total
                            </span>
                            <span className="font-weight-bolder text-dark-50 text-right">
                                $1840.00
                            </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-7">
                            <span className="font-weight-bold text-muted font-size-sm mr-2">
                                Sub total
                            </span>
                            <span className="font-weight-bolder text-primary text-right">
                                $5640.00
                            </span>
                        </div> */}
                        <div className="text-right">
                            {itemCount > 0 && (
                                <NavLink
                                    to="/cart"
                                    className="btn btn-feels rounded-pill text-weight-bold"
                                >
                                    ดูตะกร้า
                                </NavLink>
                            )}
                        </div>
                    </div>
                </form>
            </Dropdown.Menu>
        </Dropdown>
    );
}
