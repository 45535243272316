import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
    Updated: "[Update Cart] Action",
    Loaded: "[Loaded Cart] Action",
};

const initialCartState = {
    cartlist: undefined,
};

export const reducer = persistReducer(
    {
        storage,
        key: "v-014-state-cart",
        whitelist: ["cartlist"],
    },
    (state = initialCartState, action) => {
        switch (action.type) {
            case actionTypes.Updated: {
                const { cartlist } = action.payload;
                return { cartlist };
            }

            case actionTypes.Loaded: {
                const { cartlist } = action.payload;
                return { ...state, cartlist };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    update: (cartlist) => ({
        type: actionTypes.Updated,
        payload: { cartlist },
    }),
    load: (cartlist) => ({
        type: actionTypes.Loaded,
        payload: { cartlist },
    }),
};
