import React, { useState, useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";

import NumberFormat from "react-number-format";

import environment from "../environments/environment";

export const OrderDetailPage = () => {
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(false);
    // const [orderid, setOrderid] = useState("");
    const [orderresult, setOrderresult] = useState([]);
    const [orderitems, setOrderitems] = useState([]);
    const [reciver, setReciver] = useState({});
    const [address, setAddress] = useState({});
    const [total, setTotal] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [discount, setDiscount] = useState(0);
    // const [alltotal, setAllTotal] = useState(0);
    const [lenorderitems, setLenOrderitems] = useState(0);

    useEffect(() => {
        if (location.state !== undefined) {
            let sessionorder = location.state.order_number.toString();
            getOrderDetail(sessionorder);
        } else {
            history.push("/index");
        }
    }, []);

    const getOrderDetail = (ordernumber) => {
        fetch(environment.url + environment.version + "/feelsshop/getorder", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: environment.secret,
            },
            body: JSON.stringify({
                orders_number: [ordernumber],
                options: ["detail"],
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === "0") {
                    setOrderresult(Object.values(result.result)["0"]);
                    setReciver(Object.values(result.result)["0"].receiver);
                    setAddress(Object.values(result.result)["0"].shipping);
                    setOrderitems(
                        Object.values(
                            Object.values(result.result)["0"].orderitems
                        )
                    );
                    setLenOrderitems(
                        Object.keys(
                            Object.values(result.result)["0"].orderitems
                        ).length
                    );

                    Object.values(
                        Object.values(result.result)["0"].orderitems
                    ).map((object, index) => {
                        setTotal(total + object.net_price * object.quantity);
                        setShipping(
                            shipping + object.shipping_price * object.quantity
                        );
                    });

                    Object.values(
                        Object.values(result.result)["0"].ordercouponitems
                    ).map((object, index) => {
                        setDiscount(discount + object.total_discount);
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                // setError(true);
            });
    };
    // useEffect(() => {
    //     orderitems.map((object, index) => {
    //         console.log(object);
    //     });
    // }, []);
    return (
        <>
            {!loading ? (
                <>
                    <h3 className="text-white p-10 pl-10 pr-10 pl-md-40 pr-md-40">
                        #{orderresult.order_number} (
                        {orderresult.order_status === 1
                            ? "รอการชำระเงิน"
                            : orderresult.order_status === 2
                            ? "ยกเลิก"
                            : orderresult.order_status === 3
                            ? "หมดอายุ"
                            : "ชำระแล้ว"}
                        )
                    </h3>
                    <div className="container">
                        <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="ibox">
                                        <div className="ibox-title">
                                            <span className="pull-right">
                                                (
                                                <strong>
                                                    {" " + lenorderitems + " "}
                                                </strong>
                                                ) รายการ
                                            </span>
                                            <h5>รายละเอียดสินค้าทั้งหมด</h5>
                                        </div>
                                        <div className="ibox-content d-none d-lg-block">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    {""}
                                                </div>
                                                <div className="col-md-6 my-auto">
                                                    สินค้า
                                                </div>
                                                <div className="col-md-2 my-auto text-center">
                                                    จำนวน
                                                </div>
                                                <div className="col-md-2 my-auto">
                                                    ราคาต่อชิ้น
                                                </div>
                                            </div>
                                            {orderitems.map((object, index) => {
                                                return (
                                                    <div
                                                        className="row my-5"
                                                        key={index}
                                                    >
                                                        {/* <div className="col-md-2">
                                                            <img
                                                                src={
                                                                    object
                                                                        .product_image[
                                                                        "0"
                                                                    ]
                                                                }
                                                                className="w-100 h-100"
                                                                alt={`product-${object.product_id}`}
                                                            />
                                                        </div> */}
                                                        <div className="col-md-8 my-auto">
                                                            <h3 className="text-navy">
                                                                {
                                                                    object.product_name
                                                                }
                                                            </h3>
                                                        </div>
                                                        <div className="col-md-2 my-auto">
                                                            <h3 className="text-center">
                                                                {
                                                                    object.quantity
                                                                }
                                                            </h3>
                                                        </div>
                                                        <div className="col-md-2 my-auto">
                                                            <h4 className="m-0">
                                                                <NumberFormat
                                                                    value={parseFloat(
                                                                        object.net_price
                                                                    )}
                                                                    displayType={
                                                                        "text"
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    fixedDecimalScale={
                                                                        true
                                                                    }
                                                                    thousandSeparator={
                                                                        true
                                                                    }
                                                                    suffix={
                                                                        " บาท"
                                                                    }
                                                                />
                                                            </h4>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <p>
                                                                เลขพัสดุ{" "}
                                                                <span>
                                                                    {
                                                                        object.tracking_number
                                                                    }
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="ibox-content d-block d-lg-none">
                                            {orderitems.map((object, index) => {
                                                return (
                                                    <div
                                                        className="row my-5"
                                                        key={index}
                                                    >
                                                        {/* <div className="col-4">
                                                            <div className="cart-product-imitation mx-auto"></div>
                                                        </div> */}
                                                        <div className="col-12 desc">
                                                            <div className="row">
                                                                <div className="col-12 my-auto">
                                                                    <h3 className="text-navy m-0">
                                                                        {
                                                                            object.product_name
                                                                        }
                                                                    </h3>
                                                                </div>
                                                                <div className="col-6 my-auto">
                                                                    <h4 className="m-0">
                                                                        ราคา{" "}
                                                                        <NumberFormat
                                                                            value={parseFloat(
                                                                                object.net_price
                                                                            )}
                                                                            displayType={
                                                                                "text"
                                                                            }
                                                                            decimalScale={
                                                                                2
                                                                            }
                                                                            fixedDecimalScale={
                                                                                true
                                                                            }
                                                                            thousandSeparator={
                                                                                true
                                                                            }
                                                                            suffix={
                                                                                " บาท"
                                                                            }
                                                                        />
                                                                    </h4>
                                                                </div>
                                                                <div className="col-6 my-auto">
                                                                    <h4 className="m-0">
                                                                        จำนวน{" "}
                                                                        {
                                                                            object.quantity
                                                                        }{" "}
                                                                        ขิ้น
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="ibox-content">
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <h4>ที่อยู่จัดส่ง</h4>
                                                    <p>
                                                        ชื่อผู้รับ
                                                        <span>
                                                            {" " +
                                                                reciver.firstname +
                                                                " " +
                                                                reciver.lastname}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        ที่อยู่จัดส่ง
                                                        <span>
                                                            {" " +
                                                                address.address +
                                                                " " +
                                                                address.district +
                                                                " " +
                                                                address.subdistrict +
                                                                " " +
                                                                address.province +
                                                                " " +
                                                                address.zipcode +
                                                                " " +
                                                                address.country}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="ibox">
                                        <div className="ibox-title">
                                            <h5>สรุปค่าสินค้า</h5>
                                        </div>
                                        <div className="ibox-content">
                                            <span>ยอดรวมย่อย</span>
                                            <h2 className="font-bold">
                                                <NumberFormat
                                                    value={parseFloat(total)}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                    suffix={" บาท"}
                                                />
                                            </h2>
                                            <span>การจัดส่ง</span>
                                            <h2 className="font-bold">
                                                <NumberFormat
                                                    value={parseFloat(shipping)}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                    suffix={" บาท"}
                                                />
                                            </h2>
                                            <span>ส่วนลด</span>
                                            <h2 className="font-bold">
                                                <NumberFormat
                                                    value={parseFloat(discount)}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                    suffix={" บาท"}
                                                />
                                            </h2>
                                            <hr />
                                            <span className="font-weight-bolder">
                                                ยอดรวม
                                            </span>
                                            <h2 className="font-bold">
                                                <NumberFormat
                                                    value={parseFloat(
                                                        total +
                                                            shipping -
                                                            discount
                                                    )}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                    suffix={" บาท"}
                                                />
                                            </h2>
                                        </div>
                                    </div>
                                    {/* <div className="ibox">
                                        <div className="ibox-title">
                                            <h5>Support</h5>
                                        </div>
                                        <div className="ibox-content text-center">
                                            <h3>
                                                <i className="fa fa-phone" />{" "}
                                                +43 100 783 001
                                            </h3>
                                            <span className="small">
                                                Please contact with us if you
                                                have any questions. We are
                                                avalible 24h.
                                            </span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
