import React, { useMemo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import objectPath from "object-path";
// import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";

export function HeaderMobile() {
    const location = useLocation();
    const [sticky, setSticky] = useState(false);

    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            headerLogo: uiService.getStickyLogo(),
            asideDisplay: objectPath.get(
                uiService.config,
                "aside.self.display"
            ),
            headerMenuSelfDisplay:
                objectPath.get(uiService.config, "header.menu.self.display") ===
                true,
            headerMobileCssClasses: uiService.getClasses("header_mobile", true),
            headerMobileAttributes: uiService.getAttributes("header_mobile"),
        };
    }, [uiService]);

    useEffect(() => {
        const header = document.getElementById("kt_header_mobile");
        const sticky = header.offsetTop;
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > sticky) {
                setSticky(true);
                header.classList.add("sticky");
                // if (headerText !== fixedText) {
                //     setHeaderText(fixedText);
                // }
            } else {
                setSticky(false);
                header.classList.remove("sticky");
                // if (headerText !== whenNotFixed) {
                //     setHeaderText(whenNotFixed);
                // }
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, []);

    return (
        <>
            {/*begin::Header Mobile*/}
            <div
                id="kt_header_mobile"
                sticky
                className={`header-mobile ${
                    layoutProps.headerMobileCssClasses
                } ${sticky ? " bg-white text-dark" : ""}`}
                {...layoutProps.headerMobileAttributes}
            >
                {/* begin::Logo */}
                <Link to="/">
                    {location.path !== "/product/item" ? (
                        <img
                            alt="Logo"
                            className="logo-default max-h-30px"
                            src={
                                sticky
                                    ? toAbsoluteUrl(
                                          "/media/logos/feels-logo.png"
                                      )
                                    : toAbsoluteUrl(
                                          "/media/logos/feels-logo-white.png"
                                      )
                            }
                        />
                    ) : (
                        <img
                            alt="Logo"
                            className="logo-default max-h-30px"
                            src={
                                sticky
                                    ? "../media/logos/feels-logo.png"
                                    : "../media/logos/feels-logo-white.png"
                            }
                        />
                    )}
                </Link>
                {/* end::Logo */}

                {/* begin::Toolbar */}
                <div className="d-flex align-items-center">
                    {layoutProps.asideDisplay && (
                        <button
                            className="btn p-0 burger-icon burger-icon-left"
                            id="kt_aside_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    {layoutProps.headerMenuSelfDisplay && (
                        <button
                            className="btn p-0 burger-icon burger-icon-left ml-4"
                            id="kt_header_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    {location.pathname !== "/checkout" &&
                        location.pathname !== "/cart" && <MyCartDropdown />}

                    {/* <button
            className="btn btn-icon btn-hover-transparent-white p-0 ml-3"
            id="kt_header_mobile_topbar_toggle"
          >
            <span className="svg-icon svg-icon-xl">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
          </button> */}
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header Mobile */} {/*end::Header Mobile*/}
        </>
    );
}
