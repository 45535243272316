import React, {
    Suspense,
    // lazy
} from "react";
import {
    Redirect,
    Switch,
    // Route
} from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { BuilderPage } from "./pages/BuilderPage";
// import { MyPage } from "./pages/MyPage";
// import { DashboardPage } from "./pages/DashboardPage";

// Custom Page
import { HomePage } from "./pages/Home";
import { ProductsPage } from "./pages/Products";
import { ProductDetailPage } from "./pages/ProductDetail";
import { TrackOrderPage } from "./pages/TrackOrder";
import { OrderDetailPage } from "./pages/OrderDetail";
import { CartPage } from "./pages/Cart";
import { CheckoutPage } from "./pages/Checkout";
import { OrderResultPage } from "./pages/OrderResult";

// const GoogleMaterialPage = lazy(() =>
//     import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//     import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//     import("./modules/ECommerce/pages/eCommercePage")
// );

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/index" />
                }
                <ContentRoute path="/index" component={HomePage} />
                <ContentRoute path="/products" component={ProductsPage} />
                <ContentRoute
                    path="/product/item"
                    component={ProductDetailPage}
                />
                <ContentRoute path="/cart" component={CartPage} />
                <ContentRoute path="/tracking" component={TrackOrderPage} />
                <ContentRoute path="/orderdetail" component={OrderDetailPage} />
                <ContentRoute path="/checkout" component={CheckoutPage} />
                <ContentRoute path="/result" component={OrderResultPage} />
                {/* <ContentRoute path="/dashboard" component={DashboardPage} />
                <ContentRoute path="/builder" component={BuilderPage} />
                <ContentRoute path="/my-page" component={MyPage} />
                <Route path="/google-material" component={GoogleMaterialPage} />
                <Route path="/react-bootstrap" component={ReactBootstrapPage} />
                <Route path="/e-commerce" component={ECommercePage} /> */}
                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}
