import React from "react";

export function FooterCompact({
    today,
    footerClasses,
    footerContainerClasses,
}) {
    return (
        <>
            {/* begin::Footer */}
            <div
                className={`footer bg-white py-4 d-flex flex-lg-column  ${footerClasses}`}
                id="kt_footer"
            >
                {/* begin::Container */}
                <div
                    className={`${footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
                >
                    {/* begin::Copyright */}
                    <div className="text-dark order-2 order-md-1">
                        <span className="text-muted font-weight-bold mr-2">
                            2021 &copy;
                        </span>
                        {` `}
                        <a
                            href="https://feels.co.th/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="text-dark-75 text-hover-feels"
                        >
                            Feels Telecom Corporation co. ltd.
                        </a>
                    </div>
                    {/* end::Copyright */}
                    {` `}
                    {/* begin::Nav */}
                    <div className="nav nav-dark order-1 order-md-2">
                        <a
                            href="https://feels.co.th/about"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nav-link pr-3 pl-0 text-hover-feels"
                        >
                            รู้จักกับฟีล
                        </a>
                        <a
                            href="https://feels.co.th/proviso"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nav-link px-3 text-hover-feels"
                        >
                            เงื่อนไขการให้บริการ
                        </a>
                        <a
                            href="https://feels.co.th/faq"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nav-link pl-3 pr-0 text-hover-feels"
                        >
                            คำถามที่พบบ่อย
                        </a>
                    </div>
                    {/* end::Nav */}
                </div>
                {/* end::Container */}
            </div>
            {/* end::Footer */}
        </>
    );
}
