/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
// import SVG from "react-inlinesvg";
import {
    // toAbsoluteUrl,
    checkIsActive,
} from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    };

    return (
        <div
            id="kt_header_menu"
            className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}
        >
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                        "/index"
                    )}`}
                >
                    <NavLink className="menu-link" to="/index">
                        <span className="menu-text">หน้าแรก</span>
                        {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                        "/products"
                    )}`}
                >
                    <NavLink className="menu-link" to="/products">
                        <span className="menu-text">เลือกซื้อสินค้า</span>
                        {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item menu-item-rel ${getMenuItemActive(
                        "/tracking"
                    )}`}
                >
                    <NavLink className="menu-link" to="/tracking">
                        <span className="menu-text">ตรวจสอบออร์เดอร์</span>
                        {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
                    </NavLink>
                </li>
                {/*end::1 Level*/}
            </ul>
            {/*end::Header Nav*/}
        </div>
    );
}
