import React, { useState } from "react";

import {
    // NavLink,
    useHistory,
} from "react-router-dom";

import NumberFormat from "react-number-format";

import { toAbsoluteUrl } from "../../_metronic/_helpers";

import environment from "../environments/environment";

export const TrackOrderPage = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(false);
    const [orderresult, setOrderresult] = useState({});
    const [ordernumber, setOrdernumber] = useState("");

    const searchOrder = (ordernumber) => {
        if (ordernumber === "") {
            console.log("empty order number");
            return;
        } else {
            setLoading(true);
            fetch(
                environment.url + environment.version + "/feelsshop/getorder",
                {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        secret: environment.secret,
                    },
                    body: JSON.stringify({
                        orders_number: [ordernumber],
                        options: ["detail"],
                    }),
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    setLoading(false);
                    if (result.responsecode === "0") {
                        // console.log(result.result);
                        setOrderresult(result.result);
                        // console.log(orderresult);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    // setError(true);
                });
        }
    };

    const viewOrder = (ordernumber) => {
        history.push({
            pathname: "/orderdetail",
            state: { order_number: ordernumber },
        });
        // sessionStorage.setItem("ordernumber", ordernumber);
    };
    return (
        <>
            {!loading ? (
                <>
                    <div className="w-100 d-none d-lg-block">
                        <img
                            src={toAbsoluteUrl("/media/banners/tracking.png")}
                            className="w-100"
                            alt="tracking-banner"
                        />
                    </div>
                    <div className="w-100 d-block d-lg-none">
                        <img
                            src={toAbsoluteUrl(
                                "/media/banners/tracking-mobile.png"
                            )}
                            className="w-100"
                            alt="tracking-banner-mobile"
                        />
                    </div>
                    <div className="footer-newsletter">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <h3>ตรวจสอบข้อมูลรายการสั่งซื้อ</h3>
                                    <p>
                                        ข้อมูลของเลขพัสดุจะเข้าระบบประมาณ 18:00
                                        น. ของทุกวัน
                                    </p>
                                    <form
                                        className="php-email-form"
                                        id="newsletter-form"
                                    >
                                        <input
                                            type="text"
                                            className="mb-3 form-control"
                                            name="trackcode"
                                            value={ordernumber}
                                            placeholder="หมายเลขคำสั่งซื้อ/เบอร์โทรศัพท์ติดต่อกลับ/อีเมลติดต่อกลับ"
                                            onChange={(e) =>
                                                setOrdernumber(e.target.value)
                                            }
                                        />
                                        <button
                                            type="button"
                                            onClick={() =>
                                                searchOrder(ordernumber)
                                            }
                                            className="btn btn-primary btn-lg"
                                        >
                                            ค้นหา
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {Object.keys(orderresult).length > 0 && (
                        <>
                            <div className="col-md-12 text-center py-15">
                                <div className="v-heading-v2">
                                    <h4 className="v-search-result-count">
                                        ผลลัพธ์ของการค้นหา{" "}
                                        {Object.keys(orderresult).length} รายการ
                                    </h4>
                                </div>
                            </div>
                            <div className="clearfix">
                                <ul className="xsearch-items">
                                    <li className="search-item">
                                        {Object.keys(orderresult).map(
                                            (object, index) => {
                                                let orderstatus =
                                                    orderresult[object]
                                                        .order_status;
                                                let ordernumber =
                                                    orderresult[object]
                                                        .order_number;
                                                return (
                                                    <div
                                                        className="search-item-content ml-20 mr-20"
                                                        key={index}
                                                    >
                                                        <h3 className="search-item-caption">
                                                            <div
                                                                className="cursor-pointer text-primary text-hover-primary"
                                                                // className="btn btn-link"
                                                                // to="/orderdetail"
                                                                onClick={() =>
                                                                    viewOrder(
                                                                        ordernumber
                                                                    )
                                                                }
                                                            >
                                                                #
                                                                {
                                                                    orderresult[
                                                                        object
                                                                    ]
                                                                        .order_number
                                                                }
                                                            </div>
                                                        </h3>
                                                        <div className="search-item-meta mb-5">
                                                            <ul className="list-inline">
                                                                <li className="time d-none d-lg-block">
                                                                    <h6>
                                                                        วันที่:
                                                                        {" " +
                                                                            orderresult[
                                                                                object
                                                                            ]
                                                                                .order_date}
                                                                        |
                                                                        ยอดรวม:{" "}
                                                                        <NumberFormat
                                                                            value={parseFloat(
                                                                                orderresult[
                                                                                    object
                                                                                ]
                                                                                    .total_price
                                                                            )}
                                                                            displayType={
                                                                                "text"
                                                                            }
                                                                            decimalScale={
                                                                                2
                                                                            }
                                                                            fixedDecimalScale={
                                                                                true
                                                                            }
                                                                            thousandSeparator={
                                                                                true
                                                                            }
                                                                            suffix={
                                                                                " บาท"
                                                                            }
                                                                        />
                                                                        |
                                                                        การชำระเงิน:{" "}
                                                                        <span>
                                                                            <strong>
                                                                                {orderstatus ===
                                                                                1
                                                                                    ? "รอการชำระเงิน"
                                                                                    : orderstatus ===
                                                                                      2
                                                                                    ? "ยกเลิก"
                                                                                    : orderstatus ===
                                                                                      3
                                                                                    ? "หมดอายุ"
                                                                                    : "ชำระแล้ว"}
                                                                            </strong>
                                                                        </span>
                                                                        {/* | สถานะ:{" "}
                                                                        <span className="captitalize">
                                                                            <strong>
                                                                                จัดส่งแล้ว
                                                                                XXX
                                                                            </strong>
                                                                        </span> */}
                                                                    </h6>
                                                                </li>
                                                                <li className="time d-block d-lg-none">
                                                                    <h6>
                                                                        วันที่:
                                                                        {" " +
                                                                            orderresult[
                                                                                object
                                                                            ]
                                                                                .order_date}
                                                                    </h6>
                                                                    <h6>
                                                                        ยอดรวม:{" "}
                                                                        <NumberFormat
                                                                            value={parseFloat(
                                                                                orderresult[
                                                                                    object
                                                                                ]
                                                                                    .total_price
                                                                            )}
                                                                            displayType={
                                                                                "text"
                                                                            }
                                                                            decimalScale={
                                                                                2
                                                                            }
                                                                            fixedDecimalScale={
                                                                                true
                                                                            }
                                                                            thousandSeparator={
                                                                                true
                                                                            }
                                                                            suffix={
                                                                                " บาท"
                                                                            }
                                                                        />
                                                                    </h6>
                                                                    <h6>
                                                                        การชำระเงิน:{" "}
                                                                        <span>
                                                                            <strong>
                                                                                {orderstatus ===
                                                                                1
                                                                                    ? "รอการชำระเงิน"
                                                                                    : orderstatus ===
                                                                                      2
                                                                                    ? "ยกเลิก"
                                                                                    : orderstatus ===
                                                                                      3
                                                                                    ? "หมดอายุ"
                                                                                    : "ชำระแล้ว"}
                                                                            </strong>
                                                                        </span>
                                                                    </h6>
                                                                    {/* <h6>
                                                                        สถานะ:{" "}
                                                                        <span>
                                                                            <strong>
                                                                                จัดส่งแล้ว
                                                                                XXX
                                                                            </strong>
                                                                        </span>
                                                                    </h6> */}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <button
                                                                className="btn btn-outline-primary"
                                                                // to="/orderdetail"
                                                                onClick={() =>
                                                                    viewOrder(
                                                                        ordernumber
                                                                    )
                                                                }
                                                            >
                                                                ดูรายละเอียด
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
