const Storage = (cartItems) => {
    sessionStorage.setItem(
        "cart",
        JSON.stringify(cartItems.length > 0 ? cartItems : [])
    );
};

export const sumItems = (cartItems) => {
    Storage(cartItems);
    let itemCount = cartItems.reduce(
        (total, product) => total + product.quantity,
        0
    );
    let total = cartItems
        .reduce(
            (total, product) => total + product.net_price * product.quantity,
            0
        )
        .toFixed(2);
    return { itemCount, total };
};

export const CartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_ITEM":
            if (
                !state.cartItems.find(
                    (item) => item.product_id === action.payload.product_id
                )
            ) {
                if (
                    action.payload.quantity === undefined ||
                    action.payload.quantity === null
                ) {
                    state.cartItems.push({
                        ...action.payload,
                        quantity: 1,
                    });
                } else {
                    state.cartItems.push({
                        ...action.payload,
                        quantity: action.payload.quantity,
                    });
                }
            } else {
                state.cartItems[
                    state.cartItems.findIndex(
                        (item) => item.product_id === action.payload.product_id
                    )
                ].quantity =
                    state.cartItems[
                        state.cartItems.findIndex(
                            (item) =>
                                item.product_id === action.payload.product_id
                        )
                    ].quantity + action.payload.quantity;
            }

            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems],
            };
        case "REMOVE_ITEM":
            return {
                ...state,
                ...sumItems(
                    state.cartItems.filter(
                        (item) => item.product_id !== action.payload.product_id
                    )
                ),
                cartItems: [
                    ...state.cartItems.filter(
                        (item) => item.product_id !== action.payload.product_id
                    ),
                ],
            };
        case "INCREASE":
            state.cartItems[
                state.cartItems.findIndex(
                    (item) => item.product_id === action.payload.product_id
                )
            ].quantity++;
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems],
            };
        case "DECREASE":
            state.cartItems[
                state.cartItems.findIndex(
                    (item) => item.product_id === action.payload.product_id
                )
            ].quantity--;
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems],
            };
        case "CHECKOUT":
            return {
                cartItems: [],
                checkout: true,
                ...sumItems([]),
            };
        case "CLEAR":
            return {
                cartItems: [],
                ...sumItems([]),
            };
        default:
            return state;
    }
};
